import React from "react";
import "./HomePage.css"; // Make sure to create a corresponding CSS file for styling.
import heroImage from "./images/pexels-krivec-ales-552784.jpg"; // Import the image.
import logoImage from "./images/captor.png"; // Import the logo image.
import customSoftwareImage from "./images/custom_software.jpg";
import enterpriseSolutionsImage from "./images/enterprise_solutions.jpg";
import cloudServicesImage from "./images/cloud_services.png";
import aiIntegrationImage from "./images/ai_integration.jpg";

function HomePage() {
  return (
    <div className="homepage">
      <header className="header">
        <img src={logoImage} alt="Captor Logo" className="logo" />{" "}
        {/* Logo image */}
        <a href="mailto:hello@captor.us" className="contact-button">
          Contact Us
        </a>
      </header>

      <main>
        <section
          id="hero"
          className="hero"
          style={{ backgroundImage: `url(${heroImage})` }}
        >
          <div className="hero-content">
            <h1>Empowering Businesses with Tailored Software Solutions</h1>
            <p>Connecting innovation, technology, and business growth.</p>
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <section id="services" className="services">
          <h2>Our Services</h2>
          <br></br>
          <br></br>
          <div className="services-grid">
            <div className="service-item">
              <img
                src={customSoftwareImage}
                alt="Custom Software Development"
                className="service-image"
              />
              <h3>Custom Software Development</h3>
              <p>
                Developing custom software solutions tailored to your unique
                business needs.
              </p>
            </div>

            <div className="service-item">
              <img
                src={enterpriseSolutionsImage}
                alt="enterpriseSolutionsImage"
                className="service-image"
              />
              <h3>Enterprise Solutions</h3>
              <p>
                Enhancing business operations with robust and scalable
                enterprise software applications.
              </p>
            </div>
            <div className="service-item">
              <img
                src={cloudServicesImage}
                alt="cloudServicesImage"
                className="service-image"
              />
              <h3>Cloud Services</h3>
              <p>
                Leveraging the cloud to provide flexible, scalable, and secure
                storage solutions.
              </p>
            </div>
            <div className="service-item">
              <img
                src={aiIntegrationImage}
                alt="aiIntegrationImage"
                className="service-image"
              />
              <h3>AI & Machine Learning Integration</h3>
              <p>
                Empowering your business with intelligent AI-driven tools and
                analytics.
              </p>
            </div>
          </div>
        </section>
        <br></br>
        <br></br>
        <section id="contact" className="contact">
          <div className="company-info">
            <address>
              Captor OÜ
              <br />
              A. H. Tammsaare tee 47, 11316
              <br />
              Tallinn, Estonia
            </address>
            <a href="mailto:hello@captor.us" className="email-link">
              hello@captor.us
            </a>
          </div>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Captor OÜ. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;
